exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-main-bungae-bowling-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/main/bungae-bowling.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-main-bungae-bowling-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-main-chat-foodie-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/main/chat-foodie.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-main-chat-foodie-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-main-lesson-manager-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/main/lesson-manager.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-main-lesson-manager-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-box-size-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/box-size.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-box-size-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-computer-vision-lab-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/computer-vision-lab.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-computer-vision-lab-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-im-home-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/im-home.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-im-home-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-kakao-shop-clone-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/kakao-shop-clone.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-kakao-shop-clone-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-lab-internship-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/lab-internship.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-lab-internship-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-running-alarm-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/running-alarm.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-running-alarm-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-smoke-detector-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/smoke-detector.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-smoke-detector-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-web-portfolio-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/sub/web-portfolio.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-sub-web-portfolio-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-toy-cli-tetris-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/toy/cli-tetris.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-toy-cli-tetris-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-toy-interactive-virtual-human-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/toy/interactive-virtual-human.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-toy-interactive-virtual-human-mdx" */),
  "component---src-templates-project-details-tsx-content-file-path-src-projects-toy-shooting-game-mdx": () => import("./../../../src/templates/project-details.tsx?__contentFilePath=/home/runner/work/jagaldol.github.io/jagaldol.github.io/src/projects/toy/shooting-game.mdx" /* webpackChunkName: "component---src-templates-project-details-tsx-content-file-path-src-projects-toy-shooting-game-mdx" */)
}

